import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import MediaQuery from 'react-responsive'
import Hero from '../components/hero'
import Layout from '../components/layout/layout'
import { BREAKPOINTS, LAYOUT } from '../styles/cssVariables'
import Container from '../components/layout/container'
import Button from '../components/ui-kit/button'
import SocialMediaIcons from '../components/ui-kit/socialMediaIcons'
import InfoCard from '../components/ui-kit/infoCard'
import MarqueeText from '../components/marqueeText'
import SEO from '../components/layout/seo'



const SectionContainer = styled.section`
`

const ContactBar = styled(Container)`
  display: flex;
  justify-content: center;
  padding-top: 85px;
  padding-bottom: 85px;

  @media (max-width: ${BREAKPOINTS.med}px) {
    padding-top: 60px;
    padding-bottom: 60px;

    > div {
      flex-direction: column;
      align-items: center;
    }
  }
`

const ContactPanel = styled.div`
  padding: 20px 0;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (min-width: ${BREAKPOINTS.medUp}px) {

    &:first-child {
      border-right: 1px solid #d7dce2;
    }
  }

  @media (max-width: ${BREAKPOINTS.med}px) {
    flex-direction: column;
    align-items: center;

    &:first-child {
      position: relative;
      padding-top: 0;
      padding-bottom: 30px;
    }
    &:last-child {
      padding-top: 30px;
      padding-bottom: 0;
    }
  }
`

const ContactBarLabel = styled.span`
  margin-right: 1em;
  font-size: 13px;
  letter-spacing: 0.075em;
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 10px;
  }
`

const ContactEmailLink = styled.a`
  font-size: 17px;
  color: var(--color-skobeloff);

  &:hover {
    text-decoration: underline;
  }
`

const ContactEmail = styled(Button)`
  display: inline-flex;
`

const SocialMediaIconsInline = styled(SocialMediaIcons)`
  display: inline-flex;

  svg {
    transform: scale(1.25);
    transform-origin: left center;
  }
`

const FAQHeading = styled.h2`
  text-align: center;
`

const InfoCardContainer = styled(Container)`
  padding-top: 30px;
  padding-bottom: 100px;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px 30px;
  }

  @media (max-width: ${BREAKPOINTS.med}px) {
    padding-bottom: 60px;

    > div {
      grid-template-columns: 1fr;
    }
  }
`

const MarqueeTextStyled = styled(MarqueeText)`
  padding-top: 50px;
  padding-bottom: 250px;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding-bottom: 200px;
    // padding-top: 50px;

   
  }
`

const CommunityCta = styled.a`
  display: block;
  padding: 15px 0 155px;
  margin: 0 1em;
  font-size: 28px;
  text-align: center;
  line-height: 40px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--color-violet);
  -webkit-text-fill-color: transparent;
`

class ContactPage extends React.Component {
  render() {
    const openGraphImage = get(this, 'props.data.contentfulPageContact.openGraphImage')
    const communityLink = get(this, 'props.data.contentfulSite.communityLink')
    const heroHeading = get(
      this,
      'props.data.contentfulPageContact.hero.heading'
    )
    const heroSubheading = get(
      this,
      'props.data.contentfulPageContact.hero.subheading'
    )
    const heroCtaText = get(this, 'props.data.contentfulPageContact.hero.ctaText')
    const heroCtaDestination = get(
      this,
      'props.data.contentfulPageContact.hero.ctaDestination'
    )
    const seoTitle = get(this, 'props.data.contentfulPageContact.seoTitle')
    const seoDescription = get(this, 'props.data.contentfulPageContact.seoDescription')

    const faqs = get(this, 'props.data.contentfulPageContact.faqs')

    return (
      <Layout location={this.props.location}>
        <SEO
          pageTitle={seoTitle}
          seoDescription={seoDescription}
          // ogImage={openGraphImage.gatsbyImageData.images.fallback.src}
        />

      <Hero
        id="contact"
        heading={heroHeading}
        subheading={heroSubheading}
        ctaText={heroCtaText}
        ctaDestination={heroCtaDestination}
      />

      <SectionContainer>
          <ContactBar flex={true} width={12}>
            <ContactPanel>
              <ContactBarLabel>For all enquiries</ContactBarLabel>
              <ContactEmail arrow={false}>
                <ContactEmailLink href="mailto:info@mindfulcommerce.io">
                  info@mindfulcommerce.io
                </ContactEmailLink>
              </ContactEmail>
            </ContactPanel>

            <ContactPanel>
              <ContactBarLabel>Connect on social</ContactBarLabel>
              <SocialMediaIconsInline />
            </ContactPanel>
          </ContactBar>
        </SectionContainer>

        <SectionContainer>
          <Container width={12}>
            <FAQHeading>FAQs</FAQHeading>
          </Container>
          <InfoCardContainer id="faq" width={12}>
            <InfoCard
              ctaText={faqs[0].ctaText}
              ctaDestination={faqs[0].ctaDestination}
              heading={faqs[0].heading}
              icon={faqs[0].icon}
              layout={faqs[0].layout}
              body={faqs[0].body.body}
            />
            <InfoCard
              ctaText={faqs[1].ctaText}
              ctaDestination={faqs[1].ctaDestination}
              heading={faqs[1].heading}
              icon={faqs[1].icon}
              layout={faqs[1].layout}
              body={faqs[1].body.body}
            />
            <InfoCard
              ctaText={faqs[2].ctaText}
              ctaDestination={faqs[2].ctaDestination}
              heading={faqs[2].heading}
              icon={faqs[2].icon}
              layout={faqs[2].layout}
              body={faqs[2].body.body}
            />
          </InfoCardContainer>
        </SectionContainer>
        
        <MediaQuery minWidth={769}>
          <MarqueeTextStyled
            wordArray={['Join the MindfulCommerce community']}
          />
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <Container width={12}>
            <CommunityCta href={communityLink} rel="noreferrer" target="_blank">
              Join the MindfulCommerce community ⟶
            </CommunityCta>
          </Container>
        </MediaQuery>
      </Layout>
    )
  }
}

export default ContactPage

export const contactQuery = graphql`
  query ContactQuery {
    contentfulSite {
      communityLink
    }

    contentfulPageContact {
      seoTitle
      seoDescription
      openGraphImage {
        title
        gatsbyImageData(layout: FULL_WIDTH, quality: 85)
      }
      hero {
        heading
        subheading
        ctaText
        ctaDestination
        
      }
     
      faqs {
        icon {
          file {
            url
          }
        }
        heading
        body {
          body
        }
        ctaText
        ctaDestination
        layout
      }
    }
  }
`
